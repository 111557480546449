import React, {Component} from "react";
import {Form, Input, Button, Select, DatePicker} from "antd";
import MyTable from "@/components/MyTable";
import TaskResults from "@/components/TaskResults";
import {invoiceStatusList, payTypeList} from "@js/constant";
import ReceiptModel from '@/components/ReceiptModel'

const {Option} = Select;
const {RangePicker} = DatePicker;

class Tab4Content extends Component {
    searchFormRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            searchData: {},
        };
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    onSearchFinish = (values) => {
        const {signTime, ...otherValues} = values;
        this.child.getList({
            ...otherValues,
            ...this.state.searchData,
            startTime: signTime ? signTime[0] : "",
            endTime: signTime ? signTime[1] : "",
        });
    };
    onSearchReset = () => {
        this.resetSearchForm();
        this.child.getList(this.state.searchData);
    };
    resetSearchForm = () => {
        this.searchFormRef.current.resetFields();
    };
    getList = (values) => {
        this.setState({searchData: {customerId: values.customerId}});
        this.child.getList(values);
    };
    //表格数据获取成功后赋值
    getListSuccess = (data) => {
        this.props.callback(data);
    };
    onMyTableRef = (ref) => {
        this.child = ref;
    };
    onTaskResultsRef = (ref) => {
        this.taskResultsRef = ref;
    };

    getLegnth(str) {
        let arr = [];
        if (typeof str == 'string') {
            arr = str.split(',');
        }
        return arr.length;
    }

    //查看任务成果
    showTaskResults = (record) => {
        this.taskResultsRef.open(record.id)
    };
    //查看发票组
    showInvoice = (record) => {
        this.props.showInvoice(record);
    };
    //显示自由职业者详情
    showFreelanceDetail = (record) => {
        this.props.showFreelanceDetail(record, "1");
    };

    showTaskDetail = (record) => {
        this.props.showTaskDetail(record);
    };

    render() {
        const block = {display: 'block'}
        const getOpt = (text, record, index) => {
            let html = "";
            if (record.voucherPath) {
                html = (
                    <ReceiptModel voucherPath={record.voucherPath}/>
                );
            }
            return html;
        };
        const getOpt1 = (text, record, index) => {
            if (record.employeeId) {
                return (
                    <a
                        onClick={(e) => {
                            this.showFreelanceDetail(record);
                        }} style={block}>
                        查看人员
                    </a>
                );
            }
            return "";
        };
        const getOpt2 = (text, record, index) => {
            return (<a onClick={() => {
                this.showTaskResults(record);
            }} style={block}>任务成果</a>);
        };
        const columns = [
            {title: "序号", key: "index", width: 80, render: (text, record, index) => `${index + 1}`, align: "center"},
            {
                title: "操作",
                render: (text, record, index) => (
                    <span>
						{getOpt1(text, record, index)}
                        {getOpt(text, record, index)}
                        {getOpt2(text, record, index)}
					</span>
                ),
                width: 100,
                align: "center",
            },
            { title: "任务编号", dataIndex: "code", key: "code", width: 160, align: "center" },
            {
                title: "任务名称",
                dataIndex: "taskName",
                key: "taskName",
                align: "center",
                render: (text, record) => (
                    <span>
						<a
                            onClick={() => {
                                this.showTaskDetail(record);
                            }}>
							{text}
						</a>
                        &nbsp;&nbsp;
					</span>
                ),
            },
            // {
            // 	title: "任务类型",
            // 	dataIndex: "taskType",
            // 	key: "taskType",
            // 	width: 200,
            // 	align: "center",
            // 	filter: (text, record) =>
            // 		text ? (record.parentTaskType ? `${record.parentTaskType} - ${record.taskType}` : record.taskType) : "",
            // },
            // { title: "任务内容", dataIndex: "taskContent", key: "taskContent", width: 200, align: "center" },
            {title: "姓名", dataIndex: "userName", key: "userName", width: 200, align: "center"},
            {title: "证件号码", dataIndex: "credentialsNo", key: "credentialsNo", width: 200, align: "center"},
            {title: "手机号码", dataIndex: "phone", key: "phone", width: 200, align: "center"},
            // {
            //     title: "发单时间",
            //     dataIndex: "publishTime",
            //     key: "publishTime",
            //     filter: (text) => (text ? React.$moment(text).format("YYYY-MM-DD") : ""),
            //     align: "center",
            // },
            // {
            //     title: "接单时间",
            //     dataIndex: "acceptTime",
            //     key: "acceptTime",
            //     filter: (text) => (text ? React.$moment(text).format("YYYY-MM-DD") : ""),
            //     align: "center",
            // },
            // {
            //     title: "完成时间",
            //     dataIndex: "finishTime",
            //     key: "finishTime",
            //     filter: (text) => (text ? React.$moment(text).format("YYYY-MM-DD") : ""),
            //     align: "center",
            // },
            {
                title: "完成状态",
                dataIndex: "finishStatus",
                key: "finishStatus",
                filter: (text, render) => (parseInt(text) === 1 ? "已完成" : "未完成"),
                align: "center",
            },
            {
                title: "完成月份",
                dataIndex: "demandMonth",
                key: "demandMonth",
                filter: (text) => (text ? React.$moment(text).format("YYYY-MM") : ""),
                align: "center",
            },
            {
                title: "结算日期",
                dataIndex: "validTime",
                key: "validTime",
                filter: (text) => (text ? React.$moment(text).format("YYYY-MM-DD") : ""),
                align: "center",
            },
            {title: "发放状态", dataIndex: "payStatus", key: "payStatus", align: "center"},
            // {
            // 	title: "开票状态",
            // 	dataIndex: "invoiceStatus",
            // 	key: "invoiceStatus",
            // 	width: 200,
            // 	align: "center",
            // 	filter: (text, render) => React.$getLabel(invoiceStatusList, parseInt(text)),
            // },
            {
                title: "发票组数量",
                dataIndex: "invoiceGroup",
                key: "invoiceGroup",
                render: (text, record, index) => (
                    this.getLegnth(text) !== 0 ? <a onClick={(e) => {
                        this.showInvoice(record)
                    }}>{this.getLegnth(text)}</a> : '0'
                ),
                width: 180,
                align: "center",
                ellipsis: true,
            },
            {title: "发放金额", dataIndex: "amount", key: "amount", sorter: true, width: 200, align: "center"},
            {title: "银行卡号", dataIndex: "bankNo", key: "bankNo", width: 200, align: "center"},
            {
                title: "支付方式",
                dataIndex: "payType",
                key: "payType",
                align: "center",
                filter: (text, render) => React.$getLabel(payTypeList, parseInt(text)),
            },
        ];
        if (!React.$storage.get('testList').some(item => item === '0')) {
            columns.splice(2, 0,{title: '项目名称', dataIndex: 'remark', key: 'remark', width: 150, align: 'center'})
        }

        return (
            <div>
                <Form
                    className="search-form"
                    ref={this.searchFormRef}
                    name="searchForm4"
                    layout="inline"
                    onFinish={this.onSearchFinish}>
                    {/*<Form.Item name="signTime" label="发单时间：">*/}
                    {/*    <RangePicker allowClear/>*/}
                    {/*</Form.Item>*/}
                    <Form.Item name="employeeName" label="姓名：" rules={[{max: 40, message: "不得超过40个字符"}]}>
                        <Input placeholder="请输入" allowClear autoComplete="off"/>
                    </Form.Item>
                    <Form.Item name="credentialsNo" label="证件号码：" rules={[{max: 40, message: "不得超过40个字符"}]}>
                        <Input placeholder="请输入" allowClear autoComplete="off"/>
                    </Form.Item>
                    {
                        !React.$storage.get('testList').some(item => item === '0') ? (
                            <Form.Item name="remark" label="项目名称：" rules={[{max: 40, message: "不得超过40个字符"}]}>
                                <Input placeholder="请输入" allowClear autoComplete="off"/>
                            </Form.Item>
                        ) : ''
                    }
                    {/*<Form.Item name="invoiceStats" label="开票状态：">*/}
                    {/*    <Select placeholder="请选择" style={{width: 174}} allowClear>*/}
                    {/*        <Option value={0}>未开票</Option>*/}
                    {/*        <Option value={1}>已开票</Option>*/}
                    {/*    </Select>*/}
                    {/*</Form.Item>*/}
                    {/* <Form.Item name="finishStatus" label="完成状态：" >
                        <Select placeholder="请选择" style={{ width: 174 }} allowClear>
                            <Option value={0}>未完成</Option>
                            <Option value={1}>已完成</Option>
                        </Select>
                    </Form.Item> */}

                    <Form.Item>
                        <Button htmlType="submit" type="primary">
                            查询
                        </Button>
                        &nbsp;
                        <Button htmlType="button" onClick={this.onSearchReset}>
                            重置
                        </Button>
                    </Form.Item>
                </Form>

                <MyTable
                    title="接单明细"
                    onRef={this.onMyTableRef}
                    rowKey="id"
                    url="/coreCustomer/userTaskList"
                    columns={columns}
                    y={500}
                    callback={this.getListSuccess}></MyTable>
                <TaskResults onRef={this.onTaskResultsRef}></TaskResults>
            </div>
        );
    }
}

export default Tab4Content;
