import moment from 'moment'
const dateValue= [moment().startOf('month').add(-1, 'y'), moment().endOf('day')]
const defaultState = {
    signTime: sessionStorage.getItem("signTime") ? 
            [
                moment(new Date(sessionStorage.getItem("signTime").split(',')[0])),
                moment(new Date(sessionStorage.getItem("signTime").split(',')[1]))
            ] : '' 
            || dateValue
}

export default (state = defaultState, action) => {
    switch (action.type) {
        case 'changeTime': 
            defaultState.signTime = action.signTime || dateValue;
            sessionStorage.setItem("signTime", defaultState.signTime);
            break
        default:
            break
    }
    return defaultState
}