import Axios from 'axios'
import React from 'react';
import ReactDOM from 'react-dom';
import { message, Spin } from 'antd';
import { getToken } from '@js/storage'
import history from '@/router/history'

// 当前正在请求的数量
let requestCount = 0;
let messageText = ''; //当前弹出的message信息，如果一样将不重复弹出
// 显示loading
function showLoading () {
    if (requestCount === 0) {
        var dom = document.createElement('div')
        dom.setAttribute('id', 'loading')
        document.body.appendChild(dom)
        ReactDOM.render(<Spin tip="加载中..." size="large"/>, dom)
    }
    requestCount++
}

// 隐藏loading
function hideLoading () {
    requestCount--
    if (requestCount === 0) {
        document.body.removeChild(document.getElementById('loading'))
    }
}

function errMessage (msg = '系统错误') {
	if(messageText !== msg) {
		messageText = msg
		message.error(messageText, 3, () => {
			messageText = '' //关闭回调
		});
	}
}

Axios.interceptors.response.use( response => {
	return response.data;
}, error => {

	return Promise.reject(error);
});

function requestFun(url, params, type="post", isShowLoading=true, isRejectError=false) {
	params = params || {};

	let postData = {};
	for(let key in params) {
		if(typeof params[key] == 'number' || typeof params[key] == 'object') {
			postData[key] = params[key];
		}

		if(typeof params[key] == 'string' && params[key].length > 0) {
		 	postData[key] = params[key];
		}

		//日期格式的时间格式化
		console.log(params[key])
		console.log(postData[key])
		if(typeof params[key] === 'object' && !isNaN(Date.parse(params[key]))){
			postData[key] = params[key].format('YYYY-MM-DD');
		}
	}

	const config = {
		method: type,
		url: React.$config.BaseLink + url,
		data: JSON.stringify(postData),
		timeout: 1000 * 30,
		responseType: 'json',
		headers: {
			'Content-Type': 'application/json;charset=UTF-8',
			'token': getToken()
		},
		transformRequest: [function (data, headers) {
			console.log(`[${url}]`,"请求参数:",data);
			return data;
		}],
		transformResponse: [function (res) {
			console.log(`[${url}]`,"接收参数:",res);
			return res;
		}]
	};

	return new Promise((resolve, reject)=>{
		if(isShowLoading){
			showLoading()
		}
		Axios.request(config).then(res => {
            // 判断当前请求是否设置了不显示Loading
            if(isShowLoading) {
                hideLoading()
            }
			if (res.code === 0 ) {
				return resolve(res)
			}

			if (res.code === 4 ) { //登录过期
				history.push('/login')
			}
			if (res.code === 1 ) { //未登录
				history.push('/login')
			}
			if(isRejectError) {
				reject(res)
			} else {
				errMessage(res.message)
			}
		}).catch((error)=>{
            if(isShowLoading) {
                hideLoading()
			}
			let {message, code, response } = error
			if (message === 'Network Error') {
				errMessage('网络连接异常！')
			} else if (code === 'ECONNABORTED') {
				errMessage('请求超时')
			} else {
				if (response) {
					switch (response.status) {
						case 400: message = '请求错误(400)'; break;
						case 401: message = '未授权，请重新登录(401)'; break;
						case 403: message = '拒绝访问(403)'; break;
						case 404: message = '请求出错(404)'; break;
						case 408: message = '请求超时(408)'; break;
						case 500: message = '服务器错误(500)'; break;
						case 501: message = '服务未实现(501)'; break;
						case 502: message = '网络错误(502)'; break;
						case 503: message = '服务不可用(503)'; break;
						case 504: message = '网络超时(504)'; break;
						case 505: message = 'HTTP版本不受支持(505)'; break;
						default: message = `连接出错(${response.status})!`;
					}
				} else {
					message = '连接服务器失败!'
				}
				errMessage(message)
			}

			if(isRejectError) {
				reject(error)
			}

		})
	});
}

export const reqPost = (url, params, isShowLoading=true, isRejectError=false) => {
	return requestFun(url, params, "post", isShowLoading=true, isRejectError=false)
}

export const reqGet = (url, params, isShowLoading=true, isRejectError=false) => {
	return requestFun(url, params, "get", isShowLoading=true, isRejectError=false)
}

export const viewImg = (url) => {
	// console.log(process.env.PUBLIC_URL)
    return React.$config.BaseLink + '/ossFile/downloadOrPreview?fileName=' + url + '&preview=' + true
}
export const openFile = (url) => {
	// console.log(process.env.PUBLIC_URL)
    return React.$config.URL + React.$config.BaseLink + '/ossFile/downloadOrPreview?fileName=' + url + '&preview=' + true
}

export const viewPdf = (url, isShowLoading = true) => {
    const config = {
		method: 'get',
		url: React.$config.BaseLink + '/ossFile/downloadOrPreview?fileName=' + url + '&preview=' + true,
		timeout: 1000 * 30,
		headers: {
			'token': getToken()
		},
	};
	if(isShowLoading){
		showLoading()
	}
	Axios.request(config).then(res => {
		// 判断当前请求是否设置了不显示Loading
		if(isShowLoading) {
			hideLoading()
		}
		if (res.code) {
			return message.error('文件不存在');
		}
		const oa = document.createElement('a');
        oa.href = viewImg(url);
        oa.setAttribute('target', '_blank');
        document.body.appendChild(oa);
		oa.click();
	}).catch((err)=>{
		if(isShowLoading) {
			hideLoading()
		}

		message.error(err.message || '系统错误');
	})
}
