import React, {Component} from 'react'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
// import {CacheSwitch, CacheRoute} from 'react-router-cache-route';
import history from './history'

import login from "@/pages/layout/login";
import Main from "@/pages/layout/main";
import Home from "@/pages/home";
import templateSearch from "@/pages/template/search";
import templateTable from "@/pages/template/table";
import cooperativeMerchant from "@/pages/supervision/cooperativeMerchant";
// import cooperativeMerchantDetail from "@/pages/supervision/cooperativeMerchant/detail";
import companyCorePersonnel from "@/pages/supervision/companyCorePersonnel";
import freelance from "@/pages/supervision/freelance";
import invoice from "@/pages/supervision/invoice";
import paystatistics from "@/pages/statistics/paystatistics";
import wxregister from "@/pages/register/wxregister";
// import main from '../pages/layout/main';
// import freelanceDetail from "@/pages/supervision/freelance/detail";
// import templateTable from "@/pages/template/table";


const NoMatch = ({ location }) => (
    <div>
        <h3>无法匹配 <code>{location.pathname}</code></h3>
    </div>
)

const Main404 = ({ location }) => (
    <div>
        <h3>404</h3>
    </div>
)
console.log(history)
class AppRouter extends Component {
    render() {
        return (
            <Router history={history}>
                <Switch>
                    <Route path="/login" component={login} />
                    {/* <Route path="/uker_tax" component={main} /> */}
                    <Route path="/uker_tax" component={() => (
                        <Main>
                            <Switch>
                                <Route path="/uker_tax/home" component={Home} />
                                <Route path="/uker_tax/templateSearch" component={templateSearch} />
                                <Route path="/uker_tax/templateTable" component={templateTable} />
                                <Route path="/uker_tax/supervision/cooperativeMerchant" component={cooperativeMerchant} />
                                <Route path="/uker_tax/supervision/companyCorePersonnel" component={companyCorePersonnel} />
                                <Route path="/uker_tax/supervision/freelance" component={freelance} />
                                <Route path="/uker_tax/supervision/invoice" component={invoice} />
                                <Route path="/uker_tax/statistics/paystatistics" component={paystatistics} />
                                <Route path="/uker_tax/register/wxregister" component={wxregister} />
                                <Route path="/uker_tax/404" component={Main404} />
                                <Redirect exact from="/uker_tax" to="/uker_tax/home"/>
                                <Route component={NoMatch} />
                            </Switch>
                        </Main>
                    )} />
                    <Redirect exact from="/" to="/uker_tax"/>
                    <Route component={NoMatch} />

                </Switch>
            </Router>
        )
    }
}

export default AppRouter
