import React, { Component } from 'react'
import { connect } from 'react-redux'

// import { Form, Input, Button } from 'antd';
// import { UserOutlined, LockOutlined } from '@ant-design/icons';

class Search extends Component {
    state = {
        templateText: '搜索'
    }
    render() {
        return (
            <div>
                <p>{this.state.templateText}</p>
                
            </div>
        )
    }
}

export default connect()(Search)