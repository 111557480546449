import React, {Component} from 'react'
import {Modal, Tabs, Descriptions, Button} from 'antd';

import {authenticationStatusList, credentialsTypeList} from '@js/constant'


class Detail extends Component {
    isFirstReq = [0, 0, 0, 0]

    constructor(props) {
        super(props);
        const {visible, searchData, tabActiveKey} = props
        this.state = {
            visible,
            searchData,
            tabActiveKey,

            baseInfo: {}
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.visible) { //弹窗显示就初始化数据
            this.setState({...nextProps}, () => {
                this.getDetail(nextProps.tabActiveKey)
            })
        }
    }

    getDetail = (activeKey) => {
        const {searchData} = this.state
        switch (activeKey) {
            case '1': //基础信息
                this.getBaseInfo()
                break;
            case '2': //人员协议
                let tab2Interval = setInterval(() => {
                    if (this.tab2TableChild) {
                        this.tab2TableChild.getList(searchData);
                        clearInterval(tab2Interval)
                    }
                }, 20)
                // this.tab2TableChild.getList(searchData);
                break;
            case '3': //发单记录
                let tab3Interval = setInterval(() => {
                    if (this.tab3TableChild) {
                        this.tab3TableChild.getList(searchData);
                        clearInterval(tab3Interval)
                    }
                }, 20)
                break;
            case '4': //接单明细
                let tab4Interval = setInterval(() => {
                    if (this.tab4TableChild) {
                        this.tab4TableChild.getList(searchData);
                        clearInterval(tab4Interval)
                    }
                }, 20)
                break;
            default:
                break;
        }
    }
    tabChange = (activeKey) => {
        //如果是隐藏状态
        if (!this.state.visible) return

        this.setState({tabActiveKey: activeKey}, () => {
            //如果不是第一次,就不请求
            if (this.isFirstReq[parseInt(activeKey) - 1] > 0) return
            this.getDetail(activeKey)
        })

    }
    getBaseInfo = () => {
        React.$reqPost(`/employee/detail/${this.state.searchData.employeeId}`).then(({data}) => {
            this.isFirstReq[0]++
            this.setState({baseInfo: data});
        })
    }
    handleOk = () => {
        this.setState({
            visible: false,
        });
    }
    handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.isFirstReq = [0, 0, 0, 0]
        this.props.onClose();
    }
    //绑定tab2方法
    onTab2MyTableRef = (ref) => {
        this.tab2TableChild = ref
    }
    //tab2表格数据获取成功
    getTab2ListSuccess = (data) => {
        this.isFirstReq[1]++
    }
    onTab3MyTableRef = (ref) => {
        this.tab3TableChild = ref
    }
    //tab2表格数据获取成功
    getTab3ListSuccess = (data) => {
        this.isFirstReq[2]++
    }
    onTab4MyTableRef = (ref) => {
        this.tab4TableChild = ref
    }
    //tab2表格数据获取成功
    getTab4ListSuccess = (data) => {
        this.isFirstReq[3]++
    }
    showInvoice = (text) => {
        this.props.showInvoice(text)
    }

    render() {
        const SigingInfo = () => {
            return <Descriptions title="协议信息" column={1}>
                <Descriptions.Item label="协议名称">{this.state.baseInfo.agreementName}</Descriptions.Item>
                <Descriptions.Item label="协议类型">
                    {parseInt(this.state.baseInfo.contractType) === 1 ? '三方协议' : '双方协议'}
                </Descriptions.Item>
                <Descriptions.Item label="签约时间">{this.state.baseInfo.signingTime}</Descriptions.Item>
                <Descriptions.Item label="协议内容">
                    {
                        this.state.baseInfo.agreementUrl ?
                            <a href={React.$openFile(this.state.baseInfo.agreementUrl)} className="ant-btn"
                               target="_blank" rel="noopener noreferrer">查看协议</a>
                            : ''
                    }
                </Descriptions.Item>
            </Descriptions>
        }

        const NoInfo = () => {
            return <Descriptions title="协议信息">
                <Descriptions.Item>此人尚未签约！</Descriptions.Item>
            </Descriptions>

        }
        return (
            <div>
                <Modal
                    title="自由职业者详情" width="1200px" className="detail-model"
                    visible={this.props.visible}
                    onCancel={this.handleCancel}
                    footer={<Button onClick={this.handleCancel}>取消</Button>}
                >
                    <Tabs activeKey={this.state.tabActiveKey} onChange={this.tabChange}>
                        <Tabs.TabPane tab="基础信息" key="1">
                            <Descriptions title={`自由职业者（ID:${this.state.baseInfo.documentNumber || ''})`} column={4}>
                                <Descriptions.Item label="姓名">{this.state.baseInfo.userName}</Descriptions.Item>
                                <Descriptions.Item label="证件类型">
                                    {React.$getLabel(credentialsTypeList, parseInt(this.state.baseInfo.documentType))}
                                </Descriptions.Item>
                                <Descriptions.Item label="证件号码">{this.state.baseInfo.documentNumber}</Descriptions.Item>
                                <Descriptions.Item label="认证状态">
                                    已认证
                                    {this.state.baseInfo.authenticationTime === undefined ? '' : `（于${this.state.baseInfo.authenticationTime}）`}
                                </Descriptions.Item>
                                <Descriptions.Item label="联系电话">{this.state.baseInfo.mobile}</Descriptions.Item>
                                <Descriptions.Item label="银行卡号">{this.state.baseInfo.bankCard}</Descriptions.Item>
                                {/* <Descriptions.Item label="开户行">{this.state.baseInfo.openBank}</Descriptions.Item> */}
                                <Descriptions.Item label="居住地址"
                                                   span={2}>{this.state.baseInfo.address}</Descriptions.Item>
                                {this.state.baseInfo.idNumberFrontImage || this.state.baseInfo.idNumberBackImage ? (
                                    <>
                                        <Descriptions.Item label="证件正面" span={2}>
                                            <img src={React.$viewImg(this.state.baseInfo.idNumberFrontImage)}
                                                 style={{width: '80%'}} alt=""/>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="证件反面" span={2}>
                                            <img src={React.$viewImg(this.state.baseInfo.idNumberBackImage)}
                                                 style={{width: '80%'}} alt=""/>
                                        </Descriptions.Item>
                                    </>
                                ) : (
                                    <Descriptions.Item label="证件照片" span={2}>
                                        <img src={React.$viewImg(this.state.baseInfo.idNumberImage)}
                                             style={{width: '80%'}} alt=""/>
                                    </Descriptions.Item>
                                )}
                                {/*{this.state.baseInfo.identityVideoUrl && this.state.baseInfo.videoPicture === 1 ? (*/}
                                {/*    <>*/}
                                {/*    <Descriptions.Item label="视频" span={2}>*/}
                                {/*    <img src={React.$viewImg(this.state.baseInfo.identityVideoUrl)} style={{ width: '100%'}} />*/}
                                {/*    </Descriptions.Item>*/}
                                {/*    </>*/}
                                {/*) : '' }*/}
                                {/*{this.state.baseInfo.identityVideoUrl && this.state.baseInfo.videoPicture !== 1 ? (*/}
                                {/*    <>*/}
                                {/*        <Descriptions.Item label="视频" span={2}>*/}
                                {/*            <video*/}
                                {/*                controls*/}
                                {/*                width="100%"*/}
                                {/*                height="500px">*/}
                                {/*                <source src={React.$viewImg(this.state.baseInfo.identityVideoUrl)}*/}
                                {/*                        type="video/ogg"/>*/}
                                {/*                <source src={React.$viewImg(this.state.baseInfo.identityVideoUrl)}*/}
                                {/*                        type="video/mp4"/>*/}
                                {/*                <source*/}
                                {/*                    src={React.$viewImg(this.state.baseInfo.identityVideoUrl)}*/}
                                {/*                    type="video/webm"/>*/}
                                {/*                <object*/}
                                {/*                    data={React.$viewImg(this.state.baseInfo.identityVideoUrl)}*/}
                                {/*                    type="video/webm" width="100%" height="500px"/>*/}
                                {/*                <embed*/}
                                {/*                    data={React.$viewImg(this.state.baseInfo.identityVideoUrl)}*/}
                                {/*                    type="video/webm" width="100%" height="500px"/>*/}
                                {/*                您的浏览器不支持视频播放，请升级至最新版本*/}
                                {/*            </video>*/}
                                {/*        </Descriptions.Item>*/}
                                {/*    </>*/}
                                {/*) : ''}*/}
                            </Descriptions>
                            {parseInt(this.state.baseInfo.signingStatus) === 5 ?
                                SigingInfo() : NoInfo()
                            }
                        </Tabs.TabPane>
                    </Tabs>
                </Modal>
            </div>
        )
    }
}

export default Detail
