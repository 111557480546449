import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {  Button, Form, Input, DatePicker, Divider, Select, Statistic, Row, Col } from 'antd';
import Detail from './detail'
import MyTable from '@/components/MyTable'
import InvoiceGroup from '../components/invoiceGroup'
import moment from 'moment'
const { RangePicker } = DatePicker;
const {Option} = Select;
class CooperativeMerchant extends Component {
    searchFormRef = React.createRef();
    state = {
        pageTitle: '合作商户',
        totalData: {
            customerTotalNum: 0,
            employeeTotalNum: 0,
            taskTotalMoney: 0,
            taskTotalNum: 0,
            settlementTotalMoney: 0
        },
        selectedRowKeys: [],
        searchDateType: 'month',
        //详情弹框相关数据
        detailVisible: false,
        detailActiveKey: '1',
        detailSearchData: { id: '' },
        invoiceGroupVisible: false,
        invoiceGroupSearchData: {}
    }
    componentDidMount () {
        this.searchFormRef.current.setFieldsValue({signTime: this.props.signTime, type: 1})
        this.pageRefresh()
    }
    componentWillUnmount() {
        //在组件销毁的时候将异步方法撤销
        this.setState = () => {
            return
        }
    }
    pageRefresh = () => {
        const {signTime, ...otherValues} = this.searchFormRef.current.getFieldsValue();
        const [startTime, endTime] = signTime;
        const postData = {
            ...otherValues,
            startTime,
            endTime
        }
        this.child.getList(postData)
    }
    onSearchFinish = values => {
        this.pageRefresh()
    }
    onSearchReset = () => {
        this.searchFormRef.current.resetFields()
        this.props.resetSignTime()
        this.setState({searchDateType: 'month'});
        this.searchFormRef.current.setFieldsValue({type: 1})
        this.pageRefresh()
    }
    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    }
    searchTypeChange = (value) => {
        this.setState({searchDateType: value === 2 ? '' : 'month'});
        if(value !== 2) {
        }
        this.pageRefresh()
    }
    getSignTime = () => {
        const value = this.searchFormRef.current.getFieldValue('signTime')
        return this.state.searchDateType !== 'month' ? value : [`${React.$moment(value[0]).format('YYYY-MM')}-01`,
            `${React.$moment(value[1]).format('YYYY-MM')}-${React.$moment(value[1]).daysInMonth()}`]
    }
    //查看详情
    showDetail = (record, detailActiveKey) => {
        const [payFinishStartTime, payFinishEndTime] = this.getSignTime()
        console.log(payFinishStartTime, payFinishEndTime)
        this.setState({
            detailVisible: true,
            detailActiveKey,
            detailSearchData: {
                customerId: record.customerId,
                payFinishStartTime,
                payFinishEndTime
            }
        });
    }
    //详情关闭
    onDetailClose = () => {
        this.setState({ detailVisible: false });
    }
    //点击人员数，跳转到自由职业者列表
    goFreeLance = ({customerId}) => {
        // const signTime = this.searchFormRef.current.getFieldValue('signTime')
        this.props.history.push({
            pathname: "/uker_tax/supervision/freelance",
            query: {
                customerId
            }
        })
    }
    //点击发票组，弹出发票组列表
    showInvoice = (record) => {
        this.setState({
            invoiceGroupVisible: true,
            invoiceGroupSearchData: {groupIds:record.invoiceGroup}
        });
    }
    //发票组关闭
    onInvoiceClose = () => {
        this.setState({ invoiceGroupVisible: false });
    }
    //表格数据获取成功后赋值
    getListSuccess = (data) => {
        const { customerTotalNum, employeeTotalNum, taskTotalMoney, taskTotalNum, settlementTotalMoney } = data
        this.setState({totalData: { customerTotalNum, employeeTotalNum, taskTotalMoney, taskTotalNum, settlementTotalMoney }})
    }
    onMyTableRef = (ref) => {
        this.child = ref
    }
    signTimeChange = (value) => {
        this.pageRefresh()
        this.props.signTimeChange([`${React.$moment(value[0]).format('YYYY-MM')}-01`,
            `${React.$moment(value[1]).format('YYYY-MM')}-${React.$moment(value[1]).daysInMonth()}`])
    }
    getLegnth(str){
        let arr = [];
        if(typeof str == 'string'){
            arr = str.split(',');
        }
        return arr.length;
    }
    render() {
        const { selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };

        const columns = [
            { title: '序号', key: 'index', width: 80, render:(text,record,index)=> `${index+1}`,align: 'center', },
            { title: '操作', render:(text,record,index)=> (
                <a onClick={(e) => {this.showDetail(record,'1')}}>查看</a>
            ), width: 80, align: 'center'},
            { title: '商户名称', dataIndex: 'customerName', key: 'customerName', render:(text,record,index)=> (
                <a onClick={(e) => {this.showDetail(record,'1')}}>{text}</a>
            ), width: 260, align: 'center',},
            { title: '统一社会信用代码', dataIndex: 'orgCode', key: 'orgCode', render:(text,record,index)=> (
                <a onClick={(e)=> {this.showDetail(record,'1')}}>{text}</a>
                ), width: 200,  align: 'center',},
            { title: '接单人员数', dataIndex: 'employeeNum', key: 'employeeNum', render:(text,record,index)=> (
                <a onClick={(e) => {this.goFreeLance(record)}}>{text}</a>
                ), width: 150, sorter:true, align: 'center',},
            { title: '接单数', dataIndex: 'taskNum', key: 'taskNum', render:(text,record,index)=> (
                <a onClick={(e) => {this.showDetail(record,'4')}}>{text}</a>
                ), sorter:true, align: 'center', },
            { title: '接单总额', dataIndex: 'totalAmount', key: 'totalAmount', render:(text,record,index)=> (
                <a onClick={(e) => {this.showDetail(record,'4')}}>{text}</a>
                ), sorter:true, align: 'center', },
            { title: '结算总额', dataIndex: 'settlementAmount', key: 'settlementAmount', sorter:true, align: 'center'},
            { title: '对应发票组数量', dataIndex: 'invoiceGroup', key: 'invoiceGroup', render:(text,record,index)=> (
                this.getLegnth(text) !== 0 ? <a onClick={(e) => {this.showInvoice(record)}}>{this.getLegnth(text)}</a> : '0'
            ), width: 180, align: 'center',ellipsis: true },
        ];
        const month = <RangePicker picker="month" allowClear={false} onChange={this.signTimeChange}/>
        const day = <RangePicker allowClear={false} onChange={this.signTimeChange}/>
        return (
            <div>
                <p>{this.state.pageTitle}</p>
                <Form className="search-form" ref={this.searchFormRef}
                    name="searchForm" layout="inline" onFinish={this.onSearchFinish}>
                    <Form.Item name="customerName" label="商户名称：" rules={[{ max: 40, message: '不得超过40个字符'}]}>
                        <Input placeholder="请输入" allowClear autoComplete="off"/>
                    </Form.Item>
                    <Form.Item name="type" label="">
                        <Select placeholder="请选择" style={{ width: 120 }} allowClear={false} onChange={this.searchTypeChange}>
                            <Option value={1}>按月份查询</Option>
                            <Option value={2}>按日期查询</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="signTime" label="：">
                        {this.state.searchDateType === '' ? day: month}
                    </Form.Item>
                    <Form.Item name="taxNo" label="统一社会信用代码：" rules={[{ max: 40, message: '不得超过40个字符'}]}>
                        <Input placeholder="请输入" allowClear autoComplete="off"/>
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="submit" type="primary">查询</Button>&nbsp;
                        <Button htmlType="button" onClick={this.onSearchReset}>重置</Button>
                    </Form.Item>
                </Form>

                <Divider />
                <Row gutter={24}>
                    <Col span={4}>
                        <Statistic title="商户总数" value={this.state.totalData.customerTotalNum} />
                    </Col>
                    <Col span={4}>
                        <Statistic title="自由职业者总数" value={this.state.totalData.employeeTotalNum} />
                    </Col>
                    <Col span={5}>
                        <Statistic title="接单总数" value={this.state.totalData.taskTotalNum} />
                    </Col>
                    <Col span={6}>
                        <Statistic title="接单总额" value={this.state.totalData.taskTotalMoney} />
                    </Col>
                    <Col span={5}>
                        <Statistic title="结算总额" value={this.state.totalData.settlementTotalMoney} />
                    </Col>
                </Row>
                {/* <div className="list-total">
                    <span>商户总数：{this.state.totalData.customerTotalNum}</span>
                    <span>自由职业者总数：{this.state.totalData.employeeTotalNum}</span>
                    <span>接单总数：{this.state.totalData.taskTotalNum}</span>
                    <span>接单总额：{this.state.totalData.taskTotalMoney}</span>
                </div> */}
                <Divider />

                <MyTable
                    title={this.state.pageTitle}
                    onRef={this.onMyTableRef}
                    rowSelection={rowSelection}
                    rowKey="customerId"
                    url="/coreCustomer/statistics"
                    columns={columns}
                    callback={this.getListSuccess}></MyTable>

                <Detail
                    visible={this.state.detailVisible}
                    tabActiveKey={this.state.detailActiveKey}
                    searchData={this.state.detailSearchData}
                    onClose={this.onDetailClose}
                    showInvoice={this.showInvoice}></Detail>

                <InvoiceGroup visible={this.state.invoiceGroupVisible}
                    listUrl="/invoice-sale/list"
                    detailUrl="/invoice-sale/item"
                    type="cooperativeMerchant"
                    searchData={this.state.invoiceGroupSearchData}
                    onClose={this.onInvoiceClose}></InvoiceGroup>
            </div>
        )
    }
}

const mapStateToProps = ({ signTime }) => {
    return {
        signTime
    }
}

const mapDispatchToProps = dispatch => {
    return {
        signTimeChange: signTime => {
            dispatch({ signTime, type: 'changeTime' })
        },
        resetSignTime: signTime => {
            dispatch({ signTime: '', type: 'changeTime' })
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(CooperativeMerchant))
