import React, { Component } from 'react'
import { Form, Button, DatePicker } from 'antd';
import MyTable from '@/components/MyTable'
import ImgModal from '@/components/imgModal'
import Detail from '../../components/taxGroup/detail'
class Tab2Content extends Component {
    searchFormRef4 = React.createRef()
    constructor(props){
        super(props);
        this.state = {
            searchData: {},
            imgModalVisible: false,
            imgModalUrl: '',
            detailVisible:false
        }
    }
    componentDidMount () {
        this.props.onRef(this)
    }
    onSearchFinish = values => {
        const {...otherValues} = values
        otherValues.finishDate = React.$moment(otherValues.finishDate).format('YYYY-MM')
        this.child.getList({
            ...otherValues,
            groupIds: this.state.searchData.groupIds
        })
    }
    onSearchReset = () => {
        this.resetSearchForm()
        this.child.getList({groupIds: this.state.searchData.groupIds})
    }
    resetSearchForm = () => {
        this.searchFormRef4.current.resetFields()
    }
    getList = (values) => {
        this.setState({searchData: values})
        this.child.getList(values)
    }
    //表格数据获取成功后赋值
    getListSuccess = (data) => {
        this.props.callback(data);
    }
    onMyTableRef = (ref) => {
        this.child = ref
    }
    //查看详情
    showDetail = (record) => {
        this.setState({ 
            detailVisible: true,
            detailSearchData: {id: record.id}
        });
    }
    //详情关闭
    onDetailClose = () => {
        this.setState({ detailVisible: false });
    }
    showAgreement = (url) => {
        this.setState({
            imgModalVisible: true,
            imgModalUrl: url
        })
    }
    ImgModalClose = () => {
        this.setState({ imgModalVisible: false })
    }
    render() {
        const columns = [
            { title: '序号', key: "index", width: 80, render:(text,record,index)=> `${index+1}`,align: 'center', },
            { title: '操作', render:(text,record,index)=> (
                <a onClick={(e) => {this.showDetail(record)}}>查看</a>
            ), align: 'center',},
            { title: '客户名称', dataIndex: 'customerName', key: 'customerName', align: 'center',},
            { title: '完税组编号', dataIndex: 'groupOrderNo', key: 'groupOrderNo', width: 180, align: 'center',},
            { title: '完税月份', dataIndex: 'finishDate', key: 'finishDate', sorter:true, align: 'center',},
            { title: '服务商名称', dataIndex: 'channelName', key: 'channelName', align: 'center',},

        ];

        return (
            <div>
                <Form className="search-form" ref={this.searchFormRef4} name="searchForm4" layout="inline" onFinish={this.onSearchFinish} >
                    <Form.Item name="finishDate" label="月份：" >
                        <DatePicker picker="month" allowClear/>
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="submit" type="primary">查询</Button>&nbsp;
                        <Button htmlType="button" onClick={this.onSearchReset}>重置</Button>
                    </Form.Item>
                </Form>

                <MyTable
                    onRef={this.onMyTableRef}
                    title="完税信息"
                    rowKey="id"
                    url="/duty-paid-proof/list"
                    columns={columns}
                    callback={this.getListSuccess}></MyTable>
                <ImgModal
                    visible={this.state.imgModalVisible}
                    url={this.state.imgModalUrl}
                    onClose={this.ImgModalClose}></ImgModal>
                <Detail
                    visible={this.state.detailVisible}
                    url="/duty-paid-proof/item"
                    searchData={this.state.detailSearchData}
                    onClose={this.onDetailClose}></Detail>
            </div>
        )
    }
}

export default Tab2Content