import React, {Component} from "react";
import {Modal, Tabs, Button, Row, Col} from "antd";
import MyTable from '@/components/MyTable'
import ReceiptModel from '@/components/ReceiptModel'
import InvoiceGroup from '../components/invoiceGroup/detail'
class Detail extends Component {
    isFirstReq = [0, 0, 0, 0];

    constructor(props) {
        super(props);
        const {visible, searchData, tabActiveKey} = props;
        this.state = {
            visible,
            searchData,
            tabActiveKey,
            isFirstReq: {
                tableRef1: false,
                tableRef2: false,
            },
            baseInfo: {},
            taskVisible: false,
            taskData: {},
            invoiceGroupVisible: false,
            invoiceGroupSearchData: {}
        };
    }

    static getDerivedStateFromProps(nextProps, preState) {
        // if (nextProps.visible) { //弹窗显示就初始化数据
        if (preState.visible === nextProps.visible) {
            return null
        }
        return {
            visible: nextProps.visible,
            searchData: nextProps.searchData,
            tabActiveKey: nextProps.tabActiveKey,
        }
        // }
        // return null
    }

    componentDidUpdate(nextProps, prevState) {
        const {visible, searchData, tabActiveKey,} = this.props
        if (nextProps.visible === visible) {

        } else if (visible) { //弹窗显示就初始化数据
            this.setState({
                visible,
                searchData,
                tabActiveKey,
            }, () => {
                this.getDetail(this.state.tabActiveKey)
            })
        } else {
            this.setState({
                visible: false
            })
        }
    }

    onMyTableRef1 = (ref) => {
        this.tableRef1 = ref
    }
    onMyTableRef2 = (ref) => {
        this.tableRef2 = ref
    }
    tabChange = (activeKey) => {
        //如果是隐藏状态
        if (!this.props.visible) return;
        this.setState({tabActiveKey: activeKey}, () => {
            if(activeKey == 2) {
                // 添加定时器, 为了解决切换tab时，表格不显示的问题
                setTimeout(() => {
                    this.getDetail(activeKey)
                }, 500)
                return
            }
            this.getDetail(activeKey)
        });
    };
    getDetail = (activeKey) => {
        const name = [`tableRef${activeKey}`]
        if (!this.state.isFirstReq[name]) {
            this.state.isFirstReq[name] = true
            this[name].getList({groupId: this.state.searchData.groupId})
        }
    };
    handleCancel = () => {
        this.setState({
            visible: false,
            isFirstReq: {
                tableRef1: false,
                tableRef2: false,
            }
        });
        this.props.onClose();
    };

    getListSuccess = () => {

    }
    showTaskResults = (record) => {
        this.props.showTaskResults(record.detailId);
    };
    showFreelance = (record, key) => {
        this.props.showFreelance(record, key);
    };
    showCustomer = (record, key) => {
        this.props.showCustomer(record, key);
    };
    showDemandDetail = (record) => {
        this.setState({
            taskVisible: true,
            taskData: {msg: "暂无数据"}
        });
        const url = `/busDemand/detail.do?demandId=${record.demandId}`;
        React.$reqGet(url).then(({data}) => {
            if (data) {
                this.setState({taskData: data});
            } else {
                this.setState({taskData: {msg: "暂无数据"}});
            }
        });
    };
    //服务确认单
    showAcceptance = (record) => {
        this.props.showAcceptance(record);
    };
    //点击发票组，弹出发票组列表
    showInvoice = (record) => {
        this.setState({
            invoiceGroupVisible: true,
            invoiceGroupSearchData: {id:record.id}
        });
    }
    //发票组关闭
    onInvoiceClose = () => {
        this.setState({ invoiceGroupVisible: false });
    }
    statusFilter = (status) => {
        switch (status) {
            case 1:
                return "待发布";
            case 2:
                return "进行中";
            case 3:
                return "已结束";
            case 4:
                return "已取消";
            default:
                return "";
        }
    };

    priceRuleFilter = (rule) => {
        switch (rule) {
            case 1:
                return "按次数收费";
            case 2:
                return "按小时收费";
            case 3:
                return "按实际收费";
            case 4:
                return "按单价收费";
            default:
                return "";
        }
    };


    render() {
        const {taskData} = this.state;
        const style = {
            textAlign: "right",
        };
        const getOpt1 = (text, record, index) => {
            let html = "";
            if (record.voucherPath) {
                html = (
                    <ReceiptModel voucherPath={record.voucherPath} style={{paddingRight:'5px'}}/>
                );
            }
            return html;
        };
        const getOpt2 = (text, record) => {
            return (<a onClick={() => {
                this.showTaskResults(record);
            }}>任务成果</a>);
        };
        const getOpt3 = (text, record) => {
            return (<a onClick={() => {this.showInvoice(record);}}>查看</a>);
        };
        const showInfo = () => {
            const list = [
                {label: '任务名称', value: 'name'},
                {label: '任务状态', value: 'demandStatus'},
                {label: '任务类别', value: 'categoryName'},
                {label: '计价规则', value: 'priceRule'},
                {label: '任务期限', value: 'time'},
                {label: '任务发起时间', value: 'createTime'},
                {label: '任务周期', value: 'missionCycle'},
                {label: '任务区域', value: 'demandAreas'},
                {label: '任务内容', value: 'content'},
                {label: '承揽人要求', value: 'employeeRequirement'},
            ]
            const filer = {
                demandStatus: (val) => { return this.statusFilter(val)},
                priceRule: (val) => { return this.priceRuleFilter(val)},
                getValue: (val, key) => { return filer[key] !== undefined ? filer[key](val) : val}
            }
            const taskData = this.state.taskData
            taskData.time = '一个月'
            const fun = ({label, value}) => {
                return taskData[value] ? (<Row>
                    <Col span={5} style={style}>
                        {label}：
                    </Col>
                    <Col span={18}>{filer.getValue(taskData[value], value)}</Col>
                </Row>) : ''
            }
            return (
                list.map((x, index) =>
                    fun(x)
                )
            )
        };
        const columns1 = [
            {title: '序号', key: 'index', width: 80, render: (text, record, index) => `${index + 1}`, align: 'center',},
            {
                title: "操作",
                render: (text, record, index) => (
                    <span>
						{getOpt1(text, record, index)}
                        {getOpt2(text, record, index)}
					</span>
                ),
                width: 200,
                align: "center",
            },
            { title: "任务编号", dataIndex: "code", key: "code", width: 160, align: "center" },
            {
                title: "任务名称",
                dataIndex: "demandName",
                key: "demandName",
                width: 200,
                align: "center",
                render: (text, record) => (
                    <span>
						<a
                            onClick={() => {
                                this.showDemandDetail(record);
                            }}>
							{text}
						</a>
                        &nbsp;&nbsp;
					</span>
                ),
            },
            {
                title: '姓名', dataIndex: 'employeeName', key: 'employeeName', render: (text, record, index) => (
                    <a onClick={(e) => {
                        this.showFreelance(record, '1')
                    }}>{text}</a>
                ), width: 100, align: 'center',
            },
            {title: "证件号码", dataIndex: "documentNumber", key: "documentNumber", width: 200, align: "center"},
            {title: "手机号码", dataIndex: "mobile", key: "mobile", width: 200, align: "center"},
            {title: "发放金额", dataIndex: "infactPayAmount", key: "infactPayAmount", width: 200, align: "center",},
            {title: "服务费", dataIndex: "shouldPayBrokerage", key: "shouldPayBrokerage", width: 200, align: "center",},
            {title: "结算总额", dataIndex: "shouldPayAmount", key: "shouldPayAmount", width: 200, align: "center",},
            {title: "银行卡号", dataIndex: "bankCard", key: "bankCard", width: 200, align: "center"},
            {title: '批次编号', dataIndex: 'batchNo', align: 'center'}
        ];

        if (!React.$storage.get('testList').some(item => item === '0')) {
            columns1.push({title: '项目名称', dataIndex: 'remark', key: 'remark', width: 150, align: 'center'})
        }

        const columns2 = [
            {title: '序号', key: 'index', width: 80, render: (text, record, index) => `${index + 1}`, align: 'center'},
            {
                title: "操作",
                render: (text, record, index) => (
                    getOpt3(text, record, index)
                ),
                width: 200,
                align: "center",
            },
            {title: '发票号码', dataIndex: 'groupOrderNo', key: 'groupOrderNo', width: 200, align: 'center'},
            // {
            //     title: '商户名称', dataIndex: 'customerName', key: 'customerName', render: (text, record, index) => (
            //         <a onClick={(e) => {
            //             this.showCustomer(record, '1')
            //         }}>{text}</a>
            //     ), width: 260, align: 'center',
            // },
            {title: '开票金额', dataIndex: 'invoiceAmount', key: 'invoiceAmount', width: 120, align: 'center'},
        ];
        return (
            <div>
                <Modal
                    title="查看记录"
                    width="1200px"
                    className="detail-model"
                    visible={this.props.visible}
                    onCancel={this.handleCancel}
                    footer={<Button onClick={this.handleCancel}>取消</Button>}>
                    <Tabs activeKey={this.state.tabActiveKey} onChange={this.tabChange}>
                        <Tabs.TabPane tab="发放记录" key="1">
                            <MyTable
                                title="发放记录"
                                onRef={this.onMyTableRef1}
                                rowKey="detailId"
                                url="/invoice-sale/file-payroll-list"
                                columns={columns1}
                                callback={this.getListSuccess}></MyTable>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="单据组" key="2">
                            <MyTable
                                title="单据组"
                                hidePagination={true}
                                onRef={this.onMyTableRef2}
                                rowKey="id"
                                url="/invoice-sale/file-group"
                                columns={columns2}
                                callback={this.getListSuccess}></MyTable>
                        </Tabs.TabPane>
                    </Tabs>
                </Modal>

                <Modal
                    title="任务详情"
                    width="600px"
                    visible={this.state.taskVisible}
                    onCancel={() => this.setState({taskVisible: false})}
                    footer={<Button onClick={() => this.setState({taskVisible: false})}>关闭</Button>}>
                    {!taskData.msg ? (
                        <>
                            {showInfo()}
                        </>
                    ) : (
                        <div style={{textAlign: 'center'}}>{taskData.msg}</div>
                    )}
                </Modal>

                <InvoiceGroup visible={this.state.invoiceGroupVisible}
                              url="/invoice-sale/item"
                              type="cooperativeMerchant"
                              searchData={this.state.invoiceGroupSearchData}
                              onClose={this.onInvoiceClose}></InvoiceGroup>
            </div>
        );
    }
}

export default Detail;
