import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {Button, Form, Input, DatePicker, Select, Divider, Statistic, Row, Col} from 'antd';
import Detail from './detail'
import MyTable from '@/components/MyTable'
import TaxGroup from '../components/taxGroup'
import AcceptanceGroup from '../components/acceptanceGroup'
import {authenticationStatusList} from '@js/constant'
import InvoiceGroup from '../components/invoiceGroup'
import moment from 'moment'

const {Option} = Select;
const {RangePicker} = DatePicker;

class CooperativeMerchant extends Component {
    searchFormRef = React.createRef();
    state = {
        pageTitle: '自由职业者',
        totalData: {
            customerTotalNum: 0,
            employeeTotalNum: 0,
            taskTotalMoney: 0,
            taskTotalNum: 0
        },
        selectedRowKeys: [],
        searchDateType: 'month',
        //详情弹框相关数据
        detailVisible: false,
        detailActiveKey: '1',
        detailSearchData: {},
        taxGroupVisible: false,
        taxGroupSearchData: {},
        invoiceGroupVisible: false,
        invoiceGroupSearchData: {},
        acceptanceGroupVisible: false,
        acceptanceGroupSearchData: {}
    }

    componentDidMount() {
        const {query} = this.props.location
        this.searchFormRef.current.setFieldsValue({
            signTime: this.props.signTime,
            type: 1
        })

        this.pageRefresh(query)
    }

    componentWillUnmount() {
        //在组件销毁的时候将异步方法撤销
        this.setState = () => {
            return
        }
    }

    pageRefresh = (query = {}) => {
        const {signTime, ...otherValues} = this.searchFormRef.current.getFieldsValue();
        const [dateTimeStart, dateTimeEnd] = signTime;
        const postData = {
            ...otherValues,
            dateTimeStart,
            dateTimeEnd,
            ...query
        }
        this.child.getList(postData)
        this.getSummary(postData)
    }
    onSearchFinish = values => {
        this.pageRefresh()
    }
    onSearchReset = () => {
        this.searchFormRef.current.resetFields()
        this.props.resetSignTime()
        this.setState({searchDateType: 'month'});
        this.searchFormRef.current.setFieldsValue({type: 1})
        this.pageRefresh()
    }
    getSummary = (data) => {
        React.$reqPost('/employee/summary', data).then(({data}) => {
            this.setState({totalData: data})
        })
    }
    onSelectChange = selectedRowKeys => {
        this.setState({selectedRowKeys});
    }
    getSignTime = () => {
        const value = this.searchFormRef.current.getFieldValue('signTime')
        return this.state.searchDateType !== 'month' ? value : [`${React.$moment(value[0]).format('YYYY-MM')}-01`,
            `${React.$moment(value[1]).format('YYYY-MM')}-${React.$moment(value[1]).daysInMonth()}`]
    }
    //查看详情
    showDetail = (record, detailActiveKey) => {
        const [listDateTimeStart, listDateTimeEnd] = this.getSignTime()
        console.log(listDateTimeStart, listDateTimeEnd)
        const {dutyPaidProofIds, employeeId} = record
        let detailSearchData = {
            listDateTimeStart,
            listDateTimeEnd,
            employeeId,
            groupIds: dutyPaidProofIds
        }
        this.setState({
            detailVisible: true,
            detailActiveKey,
            detailSearchData
        });
    }
    //详情关闭
    onDetailClose = () => {
        this.setState({detailVisible: false});
    }
    //点击发票组，弹出发票组列表
    showInvoice = (record) => {
        this.setState({
            invoiceGroupVisible: true,
            invoiceGroupSearchData: {groupIds: record.invoiceGroup || record.invoiceGroupOrderNo}
        });
    }
    //发票组关闭
    onInvoiceClose = () => {
        this.setState({invoiceGroupVisible: false});
    }
    //点击完税组，弹出完税组列表
    showTax = (record) => {
        this.setState({
            taxGroupVisible: true,
            taxGroupSearchData: {groupIds: record.dutyPaidProofIds}
        });
    }
    //服务确认组，弹出完税组列表
    showAcceptance = (record) => {
        this.setState({
            acceptanceGroupVisible: true,
            acceptanceGroupSearchData: {groupIds: record.acceptanceGroupIds}
        });
    }
    //完税票组关闭
    onTaxClose = () => {
        this.setState({taxGroupVisible: false});
    }
    //服务确认组关闭
    onAcceptanceClose = () => {
        this.setState({acceptanceGroupVisible: false});
    }

    searchTypeChange = (value) => {
        this.setState({searchDateType: value === 2 ? '' : 'month'});
        if(value !== 2) {
        }
        this.pageRefresh()
    }
    //表格数据获取成功后赋值
    getListSuccess = (data) => {
    }
    onMyTableRef = (ref) => {
        this.child = ref
    }
    signTimeChange = (value) => {
        this.pageRefresh()
        this.props.signTimeChange(value)
    }

    getLegnth(str) {
        let arr = [];
        if (typeof str == 'string') {
            arr = str.split(',');
        }
        return arr.length;
    }

    render() {

        const {selectedRowKeys} = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };

        const columns = [
            {title: '序号', key: 'index', width: 80, render: (text, record, index) => `${index + 1}`, align: 'center',},
            {
                title: '操作', render: (text, record, index) => (
                    <a onClick={(e) => {
                        this.showDetail(record, '1')
                    }}>查看</a>
                ), width: 80, align: 'center'
            },
            {
                title: '姓名', dataIndex: 'employeeName', key: 'employeeName', render: (text, record, index) => (
                    <a onClick={(e) => {
                        this.showDetail(record, '1')
                    }}>{text}</a>
                ), width: 100, align: 'center',
            },
            {
                title: '身份证号码', dataIndex: 'idCard', key: 'idCard', render: (text, record, index) => (
                    <a onClick={(e) => {
                        this.showDetail(record, '1')
                    }}>{text}</a>
                ), width: 200, align: 'center',
            },
            {
                title: '认证状态',
                dataIndex: 'authenticationStatus',
                key: 'authenticationStatus',
                filter: (text, record, index) => (
                    // React.$getLabel(authenticationStatusList, parseInt(text))
                    '已认证'
                ),
                align: 'center',
            },
            {
                title: '接单数', dataIndex: 'orderCount', key: 'orderCount', render: (text, record, index) => (
                    <a onClick={(e) => {
                        this.showDetail(record, '2')
                    }}>{text}</a>
                ), sorter: true, align: 'center',
            },
            {
                title: '收入总额', dataIndex: 'amount', key: 'amount', render: (text, record, index) => (
                    <a onClick={(e) => {
                        this.showDetail(record, '2')
                    }}>{text}</a>
                ), sorter: true, align: 'center',
            },
            // { title: '纳税总额', dataIndex: 'taxAmount', key: 'taxAmount', render:(text,record,index)=> (
            //     <a onClick={(e) => {this.showDetail(record,'3')}}>{text}</a>
            // ), sorter:true, align: 'center',ellipsis: true },
            {
                title: '完税证明组', dataIndex: 'taxPaymentCount', key: 'taxPaymentCount', render: (text, record, index) => (
                    text > 0 ? <a onClick={(e) => {
                        this.showTax(record)
                    }}>{text}</a> : '0'
                ), align: 'center', ellipsis: true
            },
            {
                title: '对应发票组数量', dataIndex: 'invoiceGroup', key: 'invoiceGroup', render: (text, record, index) => (
                    this.getLegnth(text) !== 0 ? <a onClick={(e) => {
                        this.showInvoice(record)
                    }}>{this.getLegnth(text)}</a> : '0'
                ), width: 180, align: 'center', ellipsis: true
            },
        ];
        const month = <RangePicker picker="month" allowClear={false} onChange={this.signTimeChange}/>
        const day = <RangePicker allowClear={false} onChange={this.signTimeChange}/>
        return (
            <div className="test">
                <p>{this.state.pageTitle}</p>
                <Form className="search-form" ref={this.searchFormRef}
                      name="searchForm" layout="inline" onFinish={this.onSearchFinish}>
                    <Form.Item name="type" label="">
                        <Select placeholder="请选择" style={{width: 120}} allowClear={false} onChange={this.searchTypeChange}>
                            <Option value={1}>按月份查询</Option>
                            <Option value={2}>按日期查询</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="signTime" label="：">
                        {this.state.searchDateType === '' ? day: month}
                    </Form.Item>
                    {/* <Form.Item name="customerName" label="所属商户：" rules={[{ max: 40, message: '不得超过40个字符'}]}>
                        <Input placeholder="请输入" allowClear autoComplete="off"/>
                    </Form.Item> */}
                    <Form.Item name="employeeName" label="姓名：" rules={[{max: 40, message: '不得超过40个字符'}]}>
                        <Input placeholder="请输入" allowClear autoComplete="off"/>
                    </Form.Item>
                    <Form.Item name="documentNumber" label="证件号码：" rules={[{max: 40, message: '不得超过40个字符'}]}>
                        <Input placeholder="请输入" allowClear autoComplete="off"/>
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="submit" type="primary">查询</Button>&nbsp;
                        <Button htmlType="button" onClick={this.onSearchReset}>重置</Button>
                    </Form.Item>
                </Form>
                <Divider/>
                <Row gutter={24}>
                    <Col span={6}>
                        <Statistic title="自由职业者总数" value={this.state.totalData.employeeCount}/>
                    </Col>
                    <Col span={6}>
                        <Statistic title="接单总数" value={this.state.totalData.orderCount}/>
                    </Col>
                    <Col span={6}>
                        <Statistic title="收入总额" value={this.state.totalData.amount}/>
                    </Col>
                    {/*<Col span={4}>*/}
                    {/*    <Statistic title="纳税总额" value={this.state.totalData.taxAmount} />*/}
                    {/*</Col>*/}
                    <Col span={6}>
                        <Statistic title="完税记录" value={this.state.totalData.taxPaymentCount}/>
                    </Col>
                </Row>

                <Divider/>
                <MyTable
                    title={this.state.pageTitle}
                    onRef={this.onMyTableRef}
                    rowSelection={rowSelection}
                    rowKey="employeeId"
                    url="/employee/list"
                    columns={columns}
                    callback={this.getListSuccess}></MyTable>

                <Detail
                    visible={this.state.detailVisible}
                    tabActiveKey={this.state.detailActiveKey}
                    searchData={this.state.detailSearchData}
                    onClose={this.onDetailClose}
                    showInvoice={this.showInvoice}
                    showAcceptance={this.showAcceptance}></Detail>

                <TaxGroup visible={this.state.taxGroupVisible}
                          listUrl="/duty-paid-proof/list"
                          detailUrl="/duty-paid-proof/item"
                          searchData={this.state.taxGroupSearchData}
                          onClose={this.onTaxClose}></TaxGroup>

                <InvoiceGroup visible={this.state.invoiceGroupVisible}
                              listUrl="/invoice-cost/list"
                              detailUrl="/invoice-cost/item"
                              type="freelance"
                              searchData={this.state.invoiceGroupSearchData}
                              onClose={this.onInvoiceClose}></InvoiceGroup>

                <AcceptanceGroup visible={this.state.acceptanceGroupVisible}
                                 listUrl="/acceptance-order/list"
                                 detailUrl="/acceptance-order/item"
                                 searchData={this.state.acceptanceGroupSearchData}
                                 onClose={this.onAcceptanceClose}></AcceptanceGroup>
            </div>
        )
    }
}

const mapStateToProps = ({signTime}) => {
    return {
        signTime
    }
}

const mapDispatchToProps = dispatch => {
    return {
        signTimeChange: signTime => {
            dispatch({signTime, type: 'changeTime'})
        },
        resetSignTime: signTime => {
            dispatch({signTime: '', type: 'changeTime'})
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(CooperativeMerchant))
