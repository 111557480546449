import React, { Component } from "react";
import { Modal, Tabs, Descriptions, message, Button, Row, Col } from "antd";

import Tab2Content from "./tabs/tab2";
import Tab3Content from "./tabs/tab3";
import Tab4Content from "./tabs/tab4";
import FreelanceDetail from "./tabs/userDetail";

class Detail extends Component {
	isFirstReq = [0, 0, 0, 0];
	otherSearchData = "";
	constructor(props) {
		super(props);
		const { visible, searchData, tabActiveKey } = props;
		this.state = {
			visible,
			searchData,
			tabActiveKey,

			baseInfo: {},

			//详情弹框相关数据
			freelanceDetailVisible: false,
			freelanceDetailActiveKey: "1",
			freelanceDetailSearchData: {},
			taskVisible: false,
			taskData: {},
		};
	}
	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.visible && this.state.visible !== nextProps.visible) {
			//弹窗显示就初始化数据
			this.setState({ ...nextProps }, () => {
				this.getDetail(nextProps.tabActiveKey);
			});
		}
	}
	getDetail = (activeKey) => {
		const { searchData } = this.state;
		const { customerId } = searchData;
		switch (activeKey) {
			case "1": //基础信息
				this.getBaseInfo();
				break;
			case "2": //人员协议
				let tab2Interval = setInterval(() => {
					if (this.tab2TableChild) {
						//查询所有人员，不需要带时间
						this.tab2TableChild.getList({ customerId });
						clearInterval(tab2Interval);
					}
				}, 20);
				break;
			case "3": //发单记录
				let tab3Interval = setInterval(() => {
					if (this.tab3TableChild) {
						this.tab3TableChild.getList(searchData);
						clearInterval(tab3Interval);
					}
				}, 20);
				break;
			case "4": //接单明细
				let tab4Interval = setInterval(() => {
					if (this.tab4TableChild) {
						let tab4SearchData = {};
						if (this.isFirstReq[2] > 1) {
							//如果不是发单记录第一次请求
							tab4SearchData = { ...this.otherSearchData, customerId: searchData.customerId };
						} else {
							tab4SearchData = { ...this.otherSearchData, ...searchData };
						}
						this.tab4TableChild.getList(tab4SearchData);
						clearInterval(tab4Interval);
					}
				}, 20);
				break;
			default:
				break;
		}
	};
	tabChange = (activeKey) => {
		//如果是隐藏状态
		if (!this.state.visible) return;

		this.setState({ tabActiveKey: activeKey, taskData: {} }, () => {
			//如果不是第一次,就不请求
			if (this.isFirstReq[parseInt(activeKey) - 1] > 0) return;
			this.getDetail(activeKey);
		});
	};
	goTab4 = (record) => {
		this.otherSearchData = { taskId: record.id };
		this.setState({ tabActiveKey: "4" }, () => {
			this.getDetail("4");
		});
	};
	getBaseInfo = () => {
		React.$reqPost(`/coreCustomer/detail/${this.state.searchData.customerId}`).then(({ data }) => {
			this.isFirstReq[0]++;
			this.setState({ baseInfo: data });
		});
	};
	handleCancel = () => {
		this.setState({
			visible: false,
		});
		this.isFirstReq = [0, 0, 0, 0];

		//清空子组件查询表单
		this.tab2TableChild && this.tab2TableChild.resetSearchForm();
		this.tab3TableChild && this.tab3TableChild.resetSearchForm();
		this.tab4TableChild && this.tab4TableChild.resetSearchForm();

		this.props.onClose();
	};

	//tab2表格数据获取成功
	getTab2ListSuccess = (data) => {
		this.isFirstReq[1]++;
	};

	//tab2表格数据获取成功
	getTab3ListSuccess = (data) => {
		this.isFirstReq[2]++;
	};

	//tab2表格数据获取成功
	getTab4ListSuccess = (data) => {
		this.isFirstReq[3]++;
		this.otherSearchData = {};
	};
	//显示发票
	showInvoice = (text) => {
		this.props.showInvoice(text);
	};
	//详情关闭
	onDetailClose = () => {
		this.setState({ freelanceDetailVisible: false });
	};
	//显示自由职业者详情
	showFreelanceDetail = (record, activeKey) => {
		if (!record.employeeId) {
			message.info("人员不存在");
			return;
		}
		this.setState({
			freelanceDetailVisible: true,
			freelanceDetailActiveKey: activeKey,
			freelanceDetailSearchData: {
				employeeId: record.employeeId,
			},
		});
	};
	statusFilter = (status) => {
		switch (status) {
			case 1:
				return "待发布";
			case 2:
				return "进行中";
			case 3:
				return "已结束";
			case 4:
				return "已取消";
			default:
				return "";
		}
	};

	priceRuleFilter = (rule) => {
		switch (rule) {
			case 1:
				return "按次数收费";
			case 2:
				return "按小时收费";
			case 3:
				return "按实际收费";
			case 4:
				return "按单价收费";
			default:
				return "";
		}
	};
	// 任务详情
	showTaskDetail = (record) => {
		this.setState({
			taskVisible: true,
		});
		const url = `/busDemand/detail.do?demandId=${record.demandId}`;
		React.$reqGet(url).then(({ data }) => {
			if (data) {
				this.setState({ taskData: data });
			} else {
				this.setState({ taskData: { msg: "暂无数据" } });
			}
		});
	};
	render() {
		const getOpt = () => {
			const list = this.state.baseInfo.protocolUrls
			if (list && list.length !== 0) {
				return (
					list.map((x, index) =>
						<Descriptions.Item label={x.originName} key={index}>
							<Button type="link" target="_blank" size="small" style={{widht: '100px'}}
									href={React.$openFile(x.path)}>
								查看协议
							</Button>
						</Descriptions.Item>
						)
				);
			}
			return "";
		};
		const getOptWork = () => {
			const list = this.state.baseInfo.officePictures
			if (list && list.length !== 0) {
				return (
					list.map((x, index) =>
						<Descriptions.Item label={x.pictureFileName} key={index}>
							<Button type="link" target="_blank" size="small" style={{widht: '100px'}}
									href={React.$openFile(x.picturePath)}>
								查看
							</Button>
						</Descriptions.Item>
						)
				);
			}
			return "";
		};
		const getOpt1 = () => {
			const businessLicense = this.state.baseInfo.businessLicencePath
			if (businessLicense) {
				return (<a href={React.$openFile(businessLicense)} className="ant-btn" target="_blank" rel="noopener noreferrer">查看营业执照</a>);
			}
			return "";
		};
		const showInfo = () => {
			const list = [
				{label: '任务名称', value: 'name'},
				{label: '任务状态', value: 'demandStatus'},
				{label: '任务类别', value: 'categoryName'},
				{label: '计价规则', value: 'priceRule'},
				{label: '任务期限', value: 'time'},
				{label: '任务发起时间', value: 'createTime'},
				{label: '任务周期', value: 'missionCycle'},
				{label: '任务区域', value: 'demandAreas'},
				{label: '任务内容', value: 'content'},
				{label: '承揽人要求', value: 'employeeRequirement'},
			]
			const filer = {
				demandStatus: (val) => { return this.statusFilter(val)},
				priceRule: (val) => { return this.priceRuleFilter(val)},
				getValue: (val, key) => { return filer[key] !== undefined ? filer[key](val) : val}
			}
			const taskData = this.state.taskData
			taskData.time = '一个月'
			const fun = ({label, value}) => {
				return taskData[value] ? (<Row>
					<Col span={5} style={style}>
						{label}：
					</Col>
					<Col span={18}>{filer.getValue(taskData[value], value)}</Col>
				</Row>) : ''
			}
			return (
				list.map((x, index) =>
					fun(x)
				)
			)
		};

		const { taskData } = this.state;
		const style = {
			textAlign: "right",
		};

		return (
			<div>
				<Modal
					title="商户详情"
					width="1200px"
					className="detail-model"
					visible={this.props.visible}
					onCancel={this.handleCancel}
					footer={<Button onClick={this.handleCancel}>取消</Button>}>
					<Tabs activeKey={this.state.tabActiveKey} onChange={this.tabChange}>
						<Tabs.TabPane tab="基础信息" key="1">
							<Descriptions title="基础信息">
								<Descriptions.Item label="名称">{this.state.baseInfo.name}</Descriptions.Item>
								<Descriptions.Item label="简称">{this.state.baseInfo.shortName}</Descriptions.Item>
								<Descriptions.Item label="统一社会信用代码">{this.state.baseInfo.orgCode}</Descriptions.Item>
								<Descriptions.Item label="开票信息">
									{this.state.baseInfo.name + "-" + this.state.baseInfo.orgCode}
								</Descriptions.Item>
								{this.state.baseInfo.contactUserName ? (<>
									<Descriptions.Item label="联系人">{this.state.baseInfo.contactUserName}</Descriptions.Item>
								</>) : ''}
								{this.state.baseInfo.contactUserPhone ? (<>
									<Descriptions.Item label="联系电话">{this.state.baseInfo.contactUserPhone}</Descriptions.Item>
								</>) : ''}
								{this.state.baseInfo.businessLicencePath ? (<>
									<Descriptions.Item label="营业执照" span={3}>
										{getOpt1()}
									</Descriptions.Item>
								</>) : ''}
							</Descriptions>
							{
								this.state.baseInfo.officePictures && this.state.baseInfo.officePictures.length !== 0 ? (<Descriptions title="办公场景">
									{getOptWork()}
								</Descriptions>) : ''
							}
							{
								this.state.baseInfo.protocolUrls && this.state.baseInfo.protocolUrls.length !== 0 ? (<Descriptions title="平台协议">
									{getOpt()}
								</Descriptions>) : ''
							}

						</Tabs.TabPane>
						<Tabs.TabPane tab="人员协议" key="2">
							<Tab2Content
								onRef={(ref) => {
									this.tab2TableChild = ref;
								}}
								callback={this.getTab2ListSuccess}
								showInvoice={this.showInvoice}
								showFreelanceDetail={this.showFreelanceDetail}></Tab2Content>
						</Tabs.TabPane>
						<Tabs.TabPane tab="发单记录" key="3">
							<Tab3Content
								onRef={(ref) => {
									this.tab3TableChild = ref;
								}}
								callback={this.getTab3ListSuccess}
								showInvoice={this.showInvoice}
								showTaskDetail={this.showTaskDetail}
								goTab4={this.goTab4}></Tab3Content>
						</Tabs.TabPane>
						<Tabs.TabPane tab="接单明细" key="4">
							<Tab4Content
								onRef={(ref) => {
									this.tab4TableChild = ref;
								}}
								callback={this.getTab4ListSuccess}
								showInvoice={this.showInvoice}
								showTaskDetail={this.showTaskDetail}
								showFreelanceDetail={this.showFreelanceDetail}></Tab4Content>
						</Tabs.TabPane>
					</Tabs>
				</Modal>

				<FreelanceDetail
					visible={this.state.freelanceDetailVisible}
					tabActiveKey={this.state.freelanceDetailActiveKey}
					searchData={this.state.freelanceDetailSearchData}
					onClose={this.onDetailClose}
					showInvoice={this.showInvoice}></FreelanceDetail>

				<Modal
					title="任务详情"
					width="600px"
					visible={this.state.taskVisible}
					onCancel={() => this.setState({ taskVisible: false })}
					footer={<Button onClick={() => this.setState({ taskVisible: false })}>关闭</Button>}>
					{!taskData.msg ? (
						<>
							{showInfo()}
						</>
					) : (
						<div style={{textAlign: 'center'}}>{taskData.msg}</div>
					)}
				</Modal>
			</div>
		);
	}
}

export default Detail;
