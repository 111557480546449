import React, { Component } from 'react'
import { Form, Input,Button, DatePicker } from 'antd';
import MyTable from '@/components/MyTable'

class Tab2Content extends Component {
    searchFormRef3 = React.createRef()
    constructor(props){
        super(props);
        this.state = {
            searchData: {}
        }
    }
    componentDidMount () {
        this.props.onRef(this)
    }
    onSearchFinish = values => {
        const { ...otherValues} = values
        this.child.getList({
            ...otherValues, 
            employeeId: this.state.searchData.employeeId
        })
    }
    onSearchReset = () => {
        this.resetSearchForm()
        this.child.getList({employeeId: this.state.searchData.employeeId})
    }
    resetSearchForm = () => {
        this.searchFormRef3.current.resetFields()
    }
    getList = (values) => {
        this.setState({searchData: values})
        this.child.getList(values)
    }
    //表格数据获取成功后赋值
    getListSuccess = (data) => {
        this.props.callback(data);
    }
    onMyTableRef = (ref) => {
        this.child = ref
    }
    shouTaskDetail = (record) => {
        this.props.goTab2(record)
    }
    render() {

        const columns = [
            { title: '序号', key: 'index', width: 80, render:(text,record,index)=> `${index+1}`,align: 'center', },
            { title: '操作', render:(text,record,index)=> (
                <span>
                    <a onClick={(e) => {this.shouTaskDetail(record)}}>查询明细任务</a>
                </span>
            )},
            { title: '所属月份', dataIndex: 'taxMonth', key: 'taxMonth', sorter:true, filter: (text) => (
                text ? React.$moment(text).format('YYYY-MM') : ''
            ), align: 'center',},
            { title: '姓名', dataIndex: 'name', key: 'name', align: 'center',},
            { title: '身份证号', dataIndex: 'idCard', key: 'idCard', align: 'center', width: 200},
            { title: '收入额', dataIndex: 'income', key: 'income', sorter:true, align: 'center', width: 100},
            { title: '个税基数', dataIndex: 'taxBase', key: 'taxBase', sorter:true, align: 'center',},
            { title: '应纳税额', dataIndex: 'tax', key: 'tax', sorter:true, align: 'center',},
        ];

        return (
            <div>
                <Form className="search-form" ref={this.searchFormRef3} name="searchForm3" layout="inline" onFinish={this.onSearchFinish} >
                    <Form.Item name="taxMonth" label="月份：" >
                        <DatePicker picker="month" allowClear/>
                    </Form.Item>
                    <Form.Item name="employeeName" label="姓名："  rules={[{ max: 40, message: '不得超过40个字符'}]}>
                        <Input placeholder="请输入" allowClear autoComplete="off"/>
                    </Form.Item>
                    <Form.Item name="documentNumber" label="证件号码："  rules={[{ max: 40, message: '不得超过40个字符'}]}>
                        <Input placeholder="请输入" allowClear autoComplete="off"/>
                    </Form.Item>

                    <Form.Item>
                        <Button htmlType="submit" type="primary">查询</Button>&nbsp;
                        <Button htmlType="button" onClick={this.onSearchReset}>重置</Button>
                    </Form.Item>
                </Form>

                <MyTable
                    onRef={this.onMyTableRef}
                    title="纳税信息"
                    rowKey="id"
                    url="/employee/tax/list"
                    columns={columns}
                    callback={this.getListSuccess}></MyTable>
            </div>
        )
    }
}

export default Tab2Content
