import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {  Button, Form, Input } from 'antd';
import Detail from './detail'
import MyTable from '@/components/MyTable'
import TaskResults from "@/components/TaskResults";
import CustomerDetail from '../cooperativeMerchant/detail'
import FreelanceDetail from '../freelance/detail'
import InvoiceGroup from '../components/invoiceGroup'
import AcceptanceGroup from "../components/acceptanceGroup";
class CooperativeMerchant extends Component {
    searchFormRef = React.createRef();
    state = {
        pageTitle: '发票记录',

        selectedRowKeys: [],
        //详情弹框相关数据
        detailVisible: false,
        detailActiveKey: '1',
        detailSearchData: {},
        customer_detailVisible: false,
        customer_detailActiveKey: '1',
        customer_detailSearchData: {},
        freelance_detailVisible: false,
        freelance_detailActiveKey: '1',
        freelance_detailSearchData: {},

        invoiceGroupVisible: false,
        invoiceGroupSearchData: {},

        acceptanceGroupVisible: false,
        acceptanceGroupSearchData: {}

    }
    componentDidMount () {
        this.pageRefresh()
    }
    componentWillUnmount() {
        //在组件销毁的时候将异步方法撤销
        this.setState = () => {
            return
        }
    }
    pageRefresh = () => {
        const query  = this.searchFormRef.current.getFieldsValue()
        const postData = {
            ...query
        }
        this.child.getList(postData)
    }
    onSearchFinish = values => {
        this.pageRefresh()
    }
    onSearchReset = () => {
        this.searchFormRef.current.resetFields()
        this.pageRefresh()
    }
    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    }
    onTaskResultsRef = (ref) => {
        this.taskResultsRef = ref;
    };
    showTaskResults = (id) => {
        this.taskResultsRef.open(id)
    };
    //查看详情
    showDetail = (record) => {
        this.setState({
            detailVisible: true,
            detailActiveKey: '1',
            detailSearchData: record
        });
    }
    //详情关闭
    onDetailClose = () => {
        this.setState({ detailVisible: false });
    }
    //表格数据获取成功后赋值
    getListSuccess = (data) => {
    }
    onMyTableRef = (ref) => {
        this.child = ref
    }
    signTimeChange = (value) => {
        this.pageRefresh()
        this.props.signTimeChange(value)
    }
    //查看详情
    showCustomerDetail = (record, key) => {
        this.setState({
            customer_detailVisible: true,
            customer_detailActiveKey: key,
            customer_detailSearchData: {
                customerId: record.customerId,
            }
        });
    }
    //查看详情
    showFreelanceDetail = (record, key) => {
        this.setState({
            freelance_detailVisible: true,
            freelance_detailActiveKey: key,
            freelance_detailSearchData: {
                employeeId: record.employeeId,
            }
        });
    }
    onCustomerDetailClose = () => {
        this.setState({ customer_detailVisible: false });
    }
    onFreelanceDetailClose = () => {
        this.setState({ freelance_detailVisible: false });
    }
    //服务确认组，弹出完税组列表
    showAcceptance = (record) => {

        this.setState({
            acceptanceGroupVisible: true,
            acceptanceGroupSearchData: {groupIds:record.acceptanceGroupIds}
        });
    }
    //服务确认组关闭
    onAcceptanceClose = () => {
        this.setState({ acceptanceGroupVisible: false });
    }
    //点击发票组，弹出发票组列表
    showInvoiceGroup = (record) => {

        this.setState({
            invoiceGroupVisible: true,
            invoiceGroupSearchData: {groupIds:record.invoiceGroup || record.invoiceGroupOrderNo}
        });
    }

    //发票组关闭
    onInvoiceClose = () => {
        this.setState({ invoiceGroupVisible: false });
    }
    render() {

        const { selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };

        const getOpt1 = (text, record, index) => {
            return (<a onClick={() => {this.showDetail(record);}}>查看记录&nbsp;&nbsp;</a>);
        };
        const getOpt2 = (text, record, index) => {
            let html = "";
            if (record.filepath) {
                html = (
                    <a href={React.$openFile(record.filepath)} target="_blank" rel="noopener noreferrer">
                        查看发票
                    </a>
                );
            }
            return html;
        };

        const columns = [
            { title: '序号', key: 'index', width: 80, render:(text,record,index)=> `${index+1}`,align: 'center', },
            {
                title: "操作",
                render: (text, record, index) => (
                    <span>
						{getOpt1(text, record, index)}
                        {getOpt2(text, record, index)}
					</span>
                ),
                width: 200,
                align: "center",
            },
            {title: '发票号码', dataIndex: 'invoiceNumber', key: 'invoiceNumber', width: 150, align: 'center'},
            { title: '商户名称', dataIndex: 'customerName', key: 'customerName', render:(text,record,index)=> (
                    <a onClick={(e) => {this.showCustomerDetail(record,'1')}}>{text}</a>
                ), width: 260, align: 'center',},
            {title: '发票金额', dataIndex: 'invoiceAmount', key: 'invoiceAmount', width: 120, align: 'center'},
        ];

        return (
            <div className="test">
                <p>{this.state.pageTitle}</p>
                <Form className="search-form" ref={this.searchFormRef} name="searchForm" layout="inline" onFinish={this.onSearchFinish}>
                    <Form.Item name="invoiceNumber" label="发票号码：" rules={[{ max: 40, message: '不得超过40个字符'}]}>
                        <Input placeholder="请输入" allowClear autoComplete="off"/>
                    </Form.Item>
                    <Form.Item name="customerName" label="商户名称：" rules={[{ max: 40, message: '不得超过40个字符'}]}>
                        <Input placeholder="请输入" allowClear autoComplete="off"/>
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="submit" type="primary">查询</Button>&nbsp;
                        <Button htmlType="button" onClick={this.onSearchReset}>重置</Button>
                    </Form.Item>
                </Form>
                <MyTable
                    title={this.state.pageTitle}
                    onRef={this.onMyTableRef}
                    rowSelection={rowSelection}
                    rowKey="groupId"
                    url="/invoice-sale/file-list"
                    columns={columns}
                    callback={this.getListSuccess}></MyTable>

                <Detail
                    visible={this.state.detailVisible}
                    tabActiveKey={this.state.detailActiveKey}
                    searchData={this.state.detailSearchData}
                    onClose={this.onDetailClose}
                    showTaskResults={this.showTaskResults}
                    showCustomer={this.showCustomerDetail}
                    showAcceptance={this.showAcceptance}
                    showFreelance={this.showFreelanceDetail}></Detail>

                <CustomerDetail
                    visible={this.state.customer_detailVisible}
                    tabActiveKey={this.state.customer_detailActiveKey}
                    searchData={this.state.customer_detailSearchData}
                    onClose={this.onCustomerDetailClose}
                    showInvoice={this.showInvoiceGroup}></CustomerDetail>

                <FreelanceDetail
                    visible={this.state.freelance_detailVisible}
                    tabActiveKey={this.state.freelance_detailActiveKey}
                    searchData={this.state.freelance_detailSearchData}
                    onClose={this.onFreelanceDetailClose}
                    showInvoice={this.showInvoiceGroup}
                    showAcceptance={this.showAcceptance}></FreelanceDetail>

                <TaskResults onRef={this.onTaskResultsRef}></TaskResults>
                <InvoiceGroup visible={this.state.invoiceGroupVisible}
                    listUrl="/invoice-sale/list"
                    detailUrl="/invoice-sale/item"
                    type="cooperativeMerchant"
                    searchData={this.state.invoiceGroupSearchData}
                    onClose={this.onInvoiceClose}></InvoiceGroup>

                <AcceptanceGroup visible={this.state.acceptanceGroupVisible}
                                 listUrl="/acceptance-order/list"
                                 detailUrl="/acceptance-order/item"
                                 searchData={this.state.acceptanceGroupSearchData}
                                 onClose={this.onAcceptanceClose}></AcceptanceGroup>
            </div>
        )
    }
}

const mapStateToProps = ({ signTime }) => {
    return {
        signTime
    }
}

export default connect(
    mapStateToProps,
)(withRouter(CooperativeMerchant))
