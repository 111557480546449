import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Typography, Row, Col } from 'antd';
import './index.less';
const { Title, Paragraph } = Typography;

class Home extends Component {
    formRef = React.createRef();
    state = {
        // tabIndex: 0
    }
    componentWillUnmount() {
        //在组件销毁的时候将异步方法撤销
        this.setState = () => {
            return
        }
    }
    render() {
        return (
            <div>
                <div style={{textAlign: 'center', paddingTop: '40px', paddingBottom: '40px'}}>
                    <img src={require("@img/home-logo.png")} alt="" style={{marginTop: '-4px'}}/>
                    <Title level={4}>{React.$storage.get('userInfo').realName}, 欢迎回来！</Title>
                    <Paragraph>中国领先的人才共享和薪税保一站式服务平台</Paragraph>
                </div>

                <div className="home-content">
                    <Row gutter={24}>
                        <Col span={8}>
                            <div className="home-list">
                                <div className="home-list-icon">
                                    <img src={require("@img/homeList1.png")} alt=""/>
                                </div>
                                <Title level={4}>税务监管</Title>
                                <Paragraph>信息查询<br />数据查询<br />税务统计</Paragraph>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div className="home-list">
                                <div className="home-list-icon">
                                    <img src={require("@img/homeList2.png")} alt=""/>
                                </div>
                                <Title level={4}>合作商户</Title>
                                <Paragraph>商户统计<br />税务统计<br />票务信息</Paragraph>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div className="home-list">
                                <div className="home-list-icon">
                                    <img src={require("@img/homeList3.png")} alt=""/>
                                </div>
                                <Title level={4}>自由职业者</Title>
                                <Paragraph>职业者信息<br />税务统计<br />完税证明</Paragraph>
                            </div>
                        </Col>
                    </Row>
                    <img src={require("@img/home-left.png")} className="home-content-left" alt=""/>
                    <img src={require("@img/home-right.png")} className="home-content-right" alt=""/>
                </div>
            </div>
        )
    }
}

export default connect()(Home)
