import React from 'react';
import ReactDOM from 'react-dom';
import Router from './router'
import { Provider } from "react-redux";
import store from "./store";
import * as serviceWorker from './serviceWorker';
import { ConfigProvider } from 'antd';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import 'moment/locale/zh-cn';
import moment from 'moment'
import './common/style/index.less'

import { reqPost, reqGet, viewImg, openFile } from '@js/api'
import { getLabel } from '@js/util'
import { storage } from '@js/storage'
import config from '@/config'

/********全局方法*********/
React.$reqPost = reqPost
React.$reqGet = reqGet
React.$viewImg = viewImg
React.$openFile = openFile
React.$getLabel = getLabel
React.$storage = storage
React.$config = config
React.$moment = moment

ReactDOM.render(
    <Provider store={store}>
      <ConfigProvider locale={zh_CN}>
        <Router />
      </ConfigProvider>
    </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
