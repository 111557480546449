import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Modal, Button, Descriptions, Table} from 'antd';
import ImgModal from '@/components/imgModal'

class InvoiceGroupDetail extends Component {
    searchFormRef = React.createRef();
    constructor(props){
        super(props);
        this.state = {
            detailData: {},
            imgModalVisible: false,
            imgModalUrl: ''
        }
    }
    state = {
        visible: false,

        selectedRowKeys: [],

        //详情弹框相关数据
        detailVisible: false,
        detailSearchData: { id: '' },

        detailData: {}
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.visible) { //弹窗显示就初始化数据
            this.setState({...nextProps},() => {
                this.getDetail()
            })
        }
    }
    getDetail = () => {
        React.$reqGet(`${this.props.url}?id=${this.props.searchData.id}`).then(({data}) => {
            this.setState({...data})
        })
    }
    handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.onClose();
    }
    //查看发票扫描件
    showInvoice = (url) => {
        this.setState({
            imgModalVisible: true,
            imgModalUrl: url
        })
    }
    ImgModalClose = () => {
        this.setState({ imgModalVisible: false })
    }
    render() {
        const viewProve = (record) =>{
            var reg = /\.(png|jpg|gif|jpeg|webp)$/;
            if (!reg.test(record.filename)) {
                return <a href={React.$openFile(record.filepath)} target="_blank" rel="noopener noreferrer">{record.filename}</a>
            }else{
                return <a onClick={(e) => {this.showInvoice(record.filepath)}}>{record.filename}</a>      
            }
        }
        //发票组信息
        const infoColumns = [
            { title: '序号', render:(text,record,index)=> `${index+1}`,align: 'center', },
            { title: '验收总额', dataIndex: 'infactPaySalary', align: 'center',},
            { title: '文件', dataIndex: 'filename', render:(text,record,index)=> (
                viewProve(record)             
            ), width: 300,  align: 'center',},
            { title: '备注', dataIndex: 'remark', align: 'center',ellipsis: true},
        ]

        //关联发放批次
        const batchColumns = [
            { title: '序号', render:(text,record,index)=> `${index+1}`,align: 'center', },
            { title: '批次编号', dataIndex: 'payrollRecordBatchNo', align: 'center',},
            { title: '个人实发总额', dataIndex: 'infactPaySalary', align: 'center',},
            { title: '服务费总额', dataIndex: 'infactPayBrokerage', align: 'center',},
            { title: '结算总额', dataIndex: 'infactPayAmount', align: 'center',},
            { title: '发放方式', dataIndex: 'online', align: 'center', render: (text) => (
                text ? '线下' : '线上'
            )},
            { title: '支付完成时间', dataIndex: 'finishTime', align: 'center', filter: (text) => (
                text ? React.$moment(text).format('YYYY-MM-DD') : ''
            )},
        ]
        return (
            <div>
                <Modal
                    title="验收单详情" width="1000px" className="detail-model"
                    visible={this.state.visible}
                    onCancel={this.handleCancel}
                    footer={<Button onClick={this.handleCancel}>取消</Button>}
                    >

                    <Descriptions title="验收单信息">
                        <Descriptions.Item label="单据组编号" span={3}>{this.state.groupOrderNo}</Descriptions.Item>
                        <Descriptions.Item label="服务商名称">{this.state.channelName}</Descriptions.Item>
                        <Descriptions.Item label="客户名称">{this.state.customerName}</Descriptions.Item>
                    </Descriptions>
                    <Descriptions title="验收单"></Descriptions>
                    <Table
                        rowKey="id"
                        columns={infoColumns}
                        dataSource={this.state.files}
                        pagination={false}
                        scroll={{y: 300}}
                        style={{marginBottom: 20}}>
                    </Table>

                    <Descriptions title="关联批次"></Descriptions>
                    <Table
                        rowKey="id"
                        columns={batchColumns}
                        dataSource={this.state.payrolls}
                        pagination={false}
                        scroll={{y: 300}}
                        style={{marginBottom: 20}}>
                    </Table>
                    
                </Modal>

                <ImgModal
                    visible={this.state.imgModalVisible}
                    url={this.state.imgModalUrl}
                    onClose={this.ImgModalClose}></ImgModal>
            </div>
        )
    }
}

export default connect()(withRouter(InvoiceGroupDetail))